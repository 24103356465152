<template>
	<!-- 交易项目 -->
	<div class="exchange-container">
		<div class="center-container">
			<div class="left-content">
				<div class="s-title s-title-b">
					<span class="decoration"></span>
					<span class="title">交易项目</span>
				</div>
				<div class="change">
					<div
						class="change-item"
						v-for="(item, index) in types"
						:key="index"
						:class="{ active: currentIndex == index }"
						@click="changeType(item, index)"
					>
						<span>{{ item.lx }}</span>
					</div>
				</div>
			</div>
			<div class="right-content">
				<div class="s-title s-title-b">
					<span>当前位置：</span>
					<span>交易项目</span> >
					<span>{{ currentType.lx }}</span>
				</div>
				<div class="info">
					<div class="query-card">
						<el-form class="query-form" :model="form" label-position="left" inline ref="form">
							<el-form-item label="项目编号" prop="code">
								<el-input v-model="form.code"></el-input>
							</el-form-item>
							<el-form-item label="关键字" prop="keyword">
								<el-input v-model="form.keyword"></el-input>
							</el-form-item>
							<div class="button" style="margin-top: 0">
								<el-form-item>
									<el-button class="query-btn" type="primary" @click="query">查询</el-button>
									<el-button class="query-btn" type="primary" @click="clear">清空</el-button>
								</el-form-item>
							</div>
						</el-form>
					</div>
					<div class="table-card">
						<el-table
							border
							class="table-head-blue"
							:data="tableData"
							style="width: 100%"
							@row-click="handleRowClick"
						>
							<el-table-column
								align="center"
								prop="xmbh"
								label="项目编号"
								width="160px"
							></el-table-column>
							<el-table-column
								align="center"
								prop="xmmc"
								label="项目名称"
								width="340px"
							></el-table-column>
							<el-table-column align="center" prop="gpjg" label="挂牌价格"></el-table-column>
							<el-table-column align="center" prop="jjzt" label="项目状态">
								<template slot-scope="scope">
									<span v-if="scope.row.jjzt == '1'">竞标中</span>
									<span v-if="scope.row.jjzt == '2'">竞标完成</span>
									<span v-if="scope.row.jjzt == '3'">流标</span>
								</template>
							</el-table-column>
							<el-table-column
								align="center"
								prop="fbsj"
								label="发布时间"
								width="160px"
							></el-table-column>
						</el-table>
						<div class="pagenation">
							<el-pagination
								background
								layout="total, slot, prev, pager, next, slot"
								:current-page="currentPage"
								:page-size="pageSize"
								:total="total"
								@current-change="currentChange"
							>
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="progress">
			<p class="title1">交易流程</p>
			<p class="title2">轻松9步即可完成交易流程</p>
			<div class="steps">
				<div class="step" v-for="(item, index) in steps" :key="item.url">
					<div>
						<img :src="item.url" alt="" />
						<p>{{ item.desc }}</p>
					</div>
					<img
						v-if="index != steps.length - 1"
						style="margin-top: 41px; margin-left: 8px"
						src="imgs/icon-bound.png"
						alt=""
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getBiddingXmxxByPg } from '@/api/homeMessage'
import { typeFilter } from '../../js/utils/util'
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			types: JSON.parse(localStorage.getItem('types')),
			routerIndex: '01',
			propertyTypes: [],
			currentIndex: 0,
			currentType: {},
			form: { code: '', keyword: '' },
			tableData: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			steps: [
				{ desc: '信息发布', url: 'imgs/exhange-step1.png' },
				{ desc: '审核', url: 'imgs/exhange-step2.png' },
				{ desc: '交易磋商', url: 'imgs/exhange-step3.png' },
				{ desc: '签订协议', url: 'imgs/exhange-step4.png' },
				{ desc: '资金监管', url: 'imgs/exhange-step5.png' },
				{ desc: '合同备案', url: 'imgs/exhange-step6.png' },
				{ desc: '交地', url: 'imgs/exhange-step7.png' },
				{ desc: '资金结算', url: 'imgs/exhange-step8.png' },
				{ desc: '登记', url: 'imgs/exhange-step9.png' },
			],
		}
	},
	watch: {
		$route: {
			handler: function () {
				let bh = this.$route.query.index
				this.routerIndex = bh
				let res = typeFilter(this.types, 'bh', bh)
				localStorage.setItem('eIndex', res.index)
				this.changeType(this.types[res.index], res.index)
			},
			immediate: true,
			// deep: true, //深度监听，同时也可监听到param参数变化
		},
		topSelectAreacode: function () {
			this.initData()
		},
	},
	created() {},

	computed: mapGetters(['topSelectAreacode']),
	methods: {
		changeType(item, index) {
			// 切换类型
			this.form = { code: '', keyword: '' }
			this.tableData = []
			this.currentPage = 1
			this.currentIndex = index
			this.currentType = item
			this.$router.push({ name: 'exchange', query: { index: item.bh } })
			this.initData()
		},
		query() {
			// 查询
			this.currentPage = 1
			this.initData()
		},
		clear() {
			// 清空
			this.currentPage = 1
			this.form = {}
			this.initData()
		},
		async initData() {
			// 获取表格数据
			let params = {
				xmbh: this.form.code,
				keyword: this.form.keyword,
				cqlx: this.currentType.bh,
				current: this.currentPage,
				size: this.pageSize,
				xzqbh: this.topSelectAreacode,
			}
			let res = await getBiddingXmxxByPg(params)
			let { status, data } = res
			if (status == 200) {
				this.tableData = data.records
				this.total = parseInt(data.total)
			}
		},
		currentChange(val) {
			// 切换分页
			this.currentPage = val
			this.initData()
		},
		handleRowClass({ row, rowIndex }) {
			// 行樣式
			if (rowIndex % 2 === 0) {
				return 'odd'
			}
		},
		handleRowClick(row, column) {
			// 点击行事件
			this.$router.push({ name: 'projectDetail', query: { c: row.xmbh } })
		},
	},
}
</script>

<style lang="less" scoped>
.exchange-container {
	// background: #fff;
	padding-top: 20px;
	padding-bottom: 32px;
	.progress {
		width: 1472px;
		margin: 0 auto;
		text-align: center;
		color: #00a0e9;
		padding-top: 35px;
		margin-top: 37px;
		background: #fff;
		height: 376px;
		.title1 {
			font-family: MicrosoftYaHei-Bold;
			font-size: 30px;
		}
		.title2 {
			font-size: 18px;
		}
		.steps {
			display: flex;
			justify-content: space-evenly;
			margin-top: 39px;
			padding-left: 35px;
			.step {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				width: 124px;
				height: 148px;
				p {
					color: #333333;
					margin-top: 30px;
				}
			}
		}
	}
  .query-form {
		display: flex;
		align-items: center;
	}
	.query-btn {
		width: 126px;
		height: 34px;
		padding-top: 9px;
	}
	.query-btn:last-child {
		width: 70px;
	}
	.change-item {
		text-align: center;
	}
}
/deep/.el-form-item {
	display: flex !important;
	align-items: center;
	margin-bottom: 0;
}
</style>

<style lang="less">
.info {
	.el-table__row {
		cursor: pointer;
	}
	.odd {
		background-color: #dff5ff !important;
	}
	.pagenation {
		.el-pagination.is-background .el-pager li:not(.disabled).active {
			border-radius: 0;
		}
	}
}
</style>
